<template>
<div id="app">
    <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>

    <v-row>
        <v-col cols="3">
            <v-select :items="listpays2" v-model="selectpays2" dense outlined item-value="id" @change="paysSearch2" label="Liste des pays">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                </template>
            </v-select>
        </v-col>
        <v-col cols="8">
            <v-select :items="listcible2" v-model="selectcible2" dense outlined item-value="id" @change="ciblesSearch2" label="Liste des Cibles">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.description }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.description }}
                </template>
            </v-select>
        </v-col>
        
        <v-col cols="5">
            <v-select :items="listindicateur2" v-model="selectindicateur2" dense outlined item-value="id" @change="indicateurSearch2" label="Recherche Indicateur ODD">
                <template slot="selection" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ data.item.code }} - {{ data.item.libelle }}
                </template>
            </v-select>
        </v-col>
        <v-col>
            <v-btn color="green" dark class="mb-2" @click="initialize()">
                Actualiser
            </v-btn>
        </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title> GRAPHE ASSOCIE A ODD EXTRAT</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">

                    <v-snackbar v-model="snackbar" :multi-line="multiLine">
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                Fermer
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                            Nouveau
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                  <v-select :items="listgraphe" v-model="selectgraphe" dense item-value="id" outlined label="Graphes">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                           {{ data.item.libelle }}
                                        </template>
                                    </v-select>
                                    <v-select :items="listpays" v-model="selectpays" @change="paysSearch" dense item-value="id" outlined label="Liste des pays">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code_pays }} - {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                          {{ data.item.code_pays }} - {{ data.item.libelle }}
                                        </template>
                                    </v-select>
                                    
                                                <v-select :items="listcible" v-model="selectcible" dense outlined item-value="id" @change="ciblesSearch" label="Liste des Cibles">
                                                    <template slot="selection" slot-scope="data">
                                                        {{ data.item.code }} - {{ data.item.description }}
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        {{ data.item.code }} - {{ data.item.description }}
                                                    </template>
                                                </v-select>
                                            
                                    <v-select :items="listindicateur" v-model="selectindicateur" dense item-value="id" outlined label="Indicateur ODD">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.libelle }}
                                        </template>
                                    </v-select>

                                    <v-text-field v-model="libelle_verticale" label="titre axe verticale " dense outlined required></v-text-field>
                                    <v-text-field v-model="libelle_horizontale" label="titre axe horizontale " dense outlined required></v-text-field>
                                    <v-text-field v-model="libelle_titre" label="titre  du graphe" dense outlined required></v-text-field>

                                </v-form>

                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">
                                Fermer
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Enregistrer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteData">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.indicateur_extrat`]="{ item }">
          {{ item.indicateur_extrat.code }} - {{ item.indicateur_extrat.libelle }}
       </template>
     
     <template v-slot:[`item.indicateur_extrat.cibles`]="{ item }">
          {{ item.indicateur_extrat.cibles.code }} - {{ item.indicateur_extrat.cibles.description }}
       </template>

        <template v-slot:[`item.indicateur_extrat.pays`]="{ item }">
          {{ item.indicateur_extrat.pays.code_pays }} - {{ item.indicateur_extrat.pays.libelle }}
       </template>

     
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" color="green" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="red" class="mr-2" small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Actualiser la liste
            </v-btn>
        </template>


    </v-data-table>

</div>
</template>

<script>
import api from '../../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        snackbartest: false,
        text: `message`,
        textsnackbar: 'messages',
        multiLine: true,
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        
        headers: [{
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '6%'
            },
            {
                text: 'ID',
                value: 'id',
                divider: true,
                width: '5%'
            },
             {
                text: 'Libelle pays',
                value: 'indicateur_extrat.pays',
                divider: true,
                width: '20%'
            },
            {
                text: 'GRAPHE ',
                value: 'graphe.libelle',
                divider: true,
                width: '15%'
            },
              {
                text: 'LIBELLE CIBLE',
                value: 'indicateur_extrat.cibles',
                divider: true,
                width: '20%'
            },
            {
                text: 'INDICATEUR ODD',
                value: 'indicateur_extrat',
                divider: true,
                width: '20%'
            },
            {
                text: 'TITRE AXE VERTICALE',
                value: 'title_axe_verticale',
                divider: true,
                width: '15%'
            },
            {
                text: 'TITRE AXE HORIZONTAL',
                value: 'title_axe_horizontale',
                divider: true,
                width: '15%'
            },
            {
                text: 'TITRE GRAPHE',
                value: 'title_haut',
                divider: true,
                width: '15%'
            },

        ],
        formTitle: 'Formulaire Graphe associé a un indicateur ODD',
        list: [],
        list2: [],

        listindicateur: [],
        selectindicateur: '',
       
        listindicateur2: [],
        selectindicateur2: '',

        listpays: [],
        selectpays: '0',
        
        listpays2: [],
        selectpays2: '0',
       
        listpays3: [],
        

        listcible: [],
        selectcible: '0',
        
        listcible3: [],
        
        listcible2: [],
        selectcible2: '0',
       

        listindicateur2: [],
        selectindicateur2: '',

        listindicateur3: [],
       

        listgraphe: [],
        selectgraphe: '',

        libelle_verticale: '',
        libelle_horizontale: '',
        libelle_titre: '',

        code_colors: '',
        valider: '0',
        id: '0',
        valid: true,

        editedIndex: -1,
        textetat: false,
        dateFormatted: null,
        messages: '',

        textmessage:'',

    }),

    methods: {
        //=====Affichages  liste des graphes==========
        readAll: async function () {
            const data = await api.readAll('parametre/all-graphe-odd-extrat');
            this.list = data.list;
             this.list2 = data.list;
           // this.listpays = data.listpays;
            //this.listcible = data.listcible;
        },
  //=====Affichages  liste des cibles==========
    
   readAll_pays_cible_indicateur: async function() {
                const data = await api.readAll('global-indicateur-cible-indicateur-extrat-pays/all');
                
                this.listpays = data.list_pays;
                this.listpays2 = data.list_pays;
                
                this.listcible = data.list_cible;
                this.listcible2 = data.list_cible;
 
                this.listindicateur3 = data.list_indicateur_extrat;
                
               
          },
      

        readAllRecherche: async function () {
            const data = await api.readAll('parametre/all-association-graphe-odd/' + this.selectindicateur2);
            this.list = data.list;
        },

        
        readgrapheAll: async function () {
            const data = await api.readAll('parametre/all-graphe');
            this.listgraphe = data.list;

        },

        fuseSearch() {
            this.readAllRecherche();
        },
    deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id);
            const res = await api.createUpdatedata('parametre/delete-graphe-odd-extrat', fd);
             if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                   // this.dialogDelete = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-check-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
                 this.dialogDelete = false;
                this.readAll();
            },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('id_graphe', this.selectgraphe);
            fd.append('id_indicateurodd_extrat', this.selectindicateur);
            fd.append('title_axe_horizontale', this.libelle_horizontale);
            fd.append('title_axe_verticale', this.libelle_verticale);
            fd.append('title_haut', this.libelle_titre);

            if (this.textetat) {

                const res = await api.createUpdatedata('parametre/update-graphe-odd-extrat/' + this.id, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            } else {
                const res = await api.createUpdatedata('parametre/add-graphe-odd-extrat', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    // this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            }

            this.clear();
            this.readAll();
        },
        initialize() {
            this.selectcible2 = '0';
            this.selectcible = '0';
            this.selectindicateur2 = '0';
            this.selectindicateur = '0';
            this.selectpays2 = '0';
            this.selectpays = '0';
            this.selectgraphe = '0';
            this.readAll();
        },

        nouveau() {
            this.selectindicateur = "";
            this.id_indicateur = "0";
            this.id = "0";
            this.textetat = false;
            this.selectgraphe = '';
            this.libelle_verticale = "";
            this.libelle_horizontale = "";
            this.libelle_titre = "";

        },

        clear() {
            this.selectindicateur = "0";
            this.selectindicateur2 = "0";
            this.id_indicateur = "0";
            this.id = "0";
            this.textetat = false;
            this.selectgraphe = '';
            this.libelle_verticale = "";
            this.libelle_horizontale = "";
            this.libelle_titre = "";

        },
        deleteItem(item) {
            this.id = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id = item.id;
            this.selectindicateur = item.id_indicateurodd_extrat;
            this.selectgraphe = item.id_graphe;
            this.libelle_horizontale = item.title_axe_horizontale;
            this.libelle_verticale = item.title_axe_verticale;
            this.libelle_titre = item.title_haut;
            this.textetat = true;
            this.dialog = true;
        },

       

        save() {
            if (this.libelle_verticale == '' || this.libelle_horizontale == '') {
                this.textmessage = "Veuillez remplir tous les champs";
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Information",
                    text: this.textmessage,
                    visible: true
                };

                return;
            }

            this.createUpdatedata();

        },

        paysSearch2(){
             this.list = [];
            this.listindicateur2 = [];
            this.listindicateur2 = this.listindicateur3.filter(this.filtrerParID_indicateur2);
            this.list = this.list2.filter(this.filtrerParID);
        },

        
        paysSearch(){
          //  this.listcible = [];
            this.listindicateur = [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID_indicateur);
        },

        ciblesSearch2(){
            this.list = [];
            this.listindicateur2 = [];
            this.listindicateur2 = this.listindicateur3.filter(this.filtrerParID_indicateur2);
            this.list = this.list2.filter(this.filtrerParID);
     
     },
      indicateurSearch2(){
            this.list = [];
            this.list = this.list2.filter(this.filtrerParID2);
     
     },

        ciblesSearch(){
            this.listindicateur = [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID_indicateur);
        },
      
      filtrerParID: function (obj) {
            if (obj.indicateur_extrat.id_cibles == this.selectcible2 && obj.indicateur_extrat.id_pays == this.selectpays2) {

                return true;
            }
             else if (obj.indicateur_extrat.id_cibles == this.selectcible2 && obj.indicateur_extrat.id_pays == this.selectpays2 && obj.id_indicateurodd_extrat == this.selectindicateur2) {

                return true;
            }
            else {
                return false;
            }
        },

         filtrerParID2: function (obj) {
          if (obj.indicateur_extrat.id_cibles == this.selectcible2 && obj.indicateur_extrat.id_pays == this.selectpays2 && obj.id_indicateurodd_extrat == this.selectindicateur2) {

                return true;
            }
            else {
                return false;
            }
        },
      
     
          filtrerParID_indicateur: function (obj) {
            if (obj.id_cibles == this.selectcible && obj.id_pays == this.selectpays) {

                return true;
            } else {
                return false;
            }
        },
         filtrerParID_indicateur2: function (obj) {
            if (obj.id_cibles == this.selectcible2 && obj.id_pays == this.selectpays2) {

                return true;
            } else {
                return false;
            }
        },
    },
    mounted() {
       this.readAll_pays_cible_indicateur();
        this.readAll();
        this.readgrapheAll();
      
    },

}
</script>
